<template>
  <Disclosure as="nav" class="bg-white shadow">
    <div class="mx-auto w-full px-2 sm:px-4 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex px-2 lg:px-0">
          <div class="flex-shrink-0 flex items-center">
            <img class="logo block lg:hidden" :src="logoSrc" alt="Your Company">
            <img class="logo hidden lg:block" :src="logoSrc" alt="Your Company">
          </div>
          <div class="hidden lg:ml-6 lg:flex lg:space-x-8">
            <Menu as="div" class="relative mt-5" v-for="(item, index) in filteredMenuItems" :key="index">
              <MenuButton class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 focus:outline-none transition duration-150 ease-in-out" :class="item.current ? 'border-yellow-500 text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'">
                {{ item.text }}
                <ChevronDownIcon class="ml-2 h-5 w-5" aria-hidden="true" v-if="item.submenu && item.submenu.length > 0" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="origin-top-right absolute z-20 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div v-for="submenuItem in item.submenu" :key="submenuItem">
                    <MenuItem>
                      <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" @click="navigateTo(submenuItem)">{{ submenuItem }}</a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>

        <div class="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
          <div class="max-w-lg w-full lg:max-w-xs">
            <label for="search" class="sr-only">Search</label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                v-model="searchQuery"
                @input="handleSearch"
                class="block w-full pl-10 pr-3 py-2 border border-transparent leading-5 bg-gray-50 text-gray-900 placeholder-gray-500 focus:outline-none focus:bg-white focus:border-white focus:ring-white sm:text-sm"
                placeholder="Search"
                type="search"
              >
              <div v-if="searchResults.length" class="absolute z-10 mt-2 w-full bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                <ul class="py-1 text-sm text-gray-700">
                  <li v-for="result in searchResults" :key="result.id" @click="selectSearchResult(result)" class="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                    {{ result.business_name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="flex lg:hidden">
          <DisclosureButton class="p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>

        <div class="hidden lg:flex lg:items-center">
          <button class="flex-shrink-0 p-1 border-2 border-transparent rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100" type="button">
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button>
          <Menu as="div" class="ml-4 relative flex-shrink-0">
            <div>
              <MenuButton class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <div class="avatar-container">
                  <img class="h-8 w-8 rounded-full" :src="require('@/assets/avatar.png')" alt="User Avatar" />
                </div>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                <MenuItem>
                  <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" @click.prevent="navigateToProfile">Your Profile</a>
                </MenuItem>
                <MenuItem>
                  <button @click.prevent="signOut" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">Sign out</button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <DisclosurePanel class="lg:hidden">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Dashboard</a>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, MagnifyingGlassIcon, Bars3Icon, BellIcon } from '@heroicons/vue/20/solid'
import { ref, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

const router = useRouter();

const navigateTo = (menuItem) => {
  console.log(`Navigating to ${menuItem}`);
  if (menuItem === 'Client Status') {
    router.push({ name: 'status' }); // Use the route name you defined
  }
  if (menuItem === 'Appointments') {
    router.push({ name: 'Appointments' }); // Use the route name you defined
  }
  if (menuItem === 'Profile') {
    router.push({ name: 'profile', params: { id: localStorage.getItem('user_id') } }); // Use the route name you defined
  }
  if (menuItem === 'User Management') {
    router.push({ name: 'Users' }); // Use the route name you defined
  }
  if (menuItem === 'Weekly Buckets') {
    router.push({ name: 'Weekly' }); // Use the route name you defined
  }
  if (menuItem === 'Sales Performace') {
    router.push({ name: 'SalesPerformance' }); // Use the route name you defined
  }
  if (menuItem === 'Historical Forecasting') {
    router.push({ name: 'HistoricalForecasting' }); // Use the route name you defined
  }
  if (menuItem === 'Product Performance') {
    router.push({ name: 'ProductPerformance' }); // Use the route name you defined
  }
  if (menuItem === 'ARB Visualization') {
    router.push({ name: 'ARBVisualization' }); // Use the route name you defined
  }
  // Add more conditions for other menu items if necessary
};

const navigateToProfile = () => {
  console.log('Navigating to profile');
  const userId = localStorage.getItem('user_id');
  if (userId) {
    router.push({ name: 'profile', params: { id: userId } });
  } else {
    console.error('User ID not found in local storage');
  }
};

// Original menu items
const menuItems = [
  {
    text: 'Onboarding',
    current: false,
    submenu: ['Appointments', 'Weekly Buckets', 'Client Status']
  },
  {
    text: 'Reports',
    current: false,
    submenu: ['Sales Performace','Historical Forecasting','Product Performance','ARB Visualization']
  },
  {
    text: 'Administration',
    current: false,
    submenu: ['User Management']
  },
];

// Filter the menu items based on user_id from localStorage
const filteredMenuItems = computed(() => {
  const userId = parseInt(localStorage.getItem('user_id'));
  if ([2, 3, 7].includes(userId)) {
    return menuItems; // Show all menu items
  } else {
    return menuItems.filter(item => item.text !== 'Reports'); // Filter out 'Reports' menu item
  }
});

const signOut = () => {
  console.log('Signing out');
  localStorage.removeItem('token'); // Remove the token from localStorage
  localStorage.removeItem('user_id'); // Remove the user_id from localStorage
  router.push({ name: 'login' }); // Redirect to /login page
  window.location.reload();
};

const searchQuery = ref('');
const searchResults = ref([]);

const handleSearch = async () => {
  if (searchQuery.value.length < 2) {
    searchResults.value = [];
    return;
  }

  const orgId = localStorage.getItem('company'); // Get the org_id from local storage

  try {
    const response = await axios.get('/search', {
      params: { query: searchQuery.value, org_id: orgId },
    });
    searchResults.value = response.data;
  } catch (error) {
    console.error('Error fetching search results:', error);
    searchResults.value = [];
  }
};

const selectSearchResult = (result) => {
  router.push({ path: `/company/${result.id}` });
  searchResults.value = [];
  searchQuery.value = '';
};

const logoSrc = computed(() => {
  const companyId = localStorage.getItem('company');
  if (companyId == 1) {
    return require('@/assets/logo.png');
  } else if (companyId == 2) {
    return require('@/assets/choice.jpg');
  } else if (companyId == 3) {
    return require('@/assets/relmedia.jpg');
  } else {
    return require('@/assets/logo.png'); // Fallback logo
  }
});
</script>

<style scoped>
.logo {
  width: 160px;
}
.avatar-container {
  background-color: white;
  border-radius: 50%;
}
</style>
