<template>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <LoadingOverlay :show="isLoading" />
    <div class="sm:flex sm:items-center justify-between">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold mt-10 leading-6 text-gray-900">Aging Report</h1>
        <p class="mt-2 text-sm text-gray-700">View client accounts based on aging categories.</p>
      </div>
      <!-- Filter Section -->
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex space-x-2">
          <!-- Date Range Filters -->
          <input v-model="filters.start_date" type="date" class="border rounded-md px-3 py-2" placeholder="Start Date" />
          <input v-model="filters.end_date" type="date" class="border rounded-md px-3 py-2" placeholder="End Date" />
          
          <!-- Industry Filter -->
          <select v-model="filters.industry" class="border rounded-md px-6 py-2">
            <option value="">All Industries</option>
            <option v-for="industry in industries" :key="industry" :value="industry">{{ industry }}</option>
          </select>

          <!-- State Filter -->
          <select v-model="filters.state" class="border rounded-md px-6 py-2">
            <option value="">All States</option>
            <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
          </select>

          <!-- Aging Filter -->
          <select v-model="filters.aging" class="border rounded-md px-6 py-2">
            <option value="">All Aging Categories</option>
            <option value="0-30 days">0-30 days</option>
            <option value="31-60 days">31-60 days</option>
            <option value="61-90 days">61-90 days</option>
            <option value="90+ days">90+ days</option>
          </select>

          <!-- Apply Filter Button -->
          <button @click="applyFilters" class="bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 rounded-md">Apply Filters</button>
        </div>
      </div>
    </div>

    <!-- Table Section for Aging -->
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle">
          <table class="min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th @click="sortTable('business_name')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Client Name</th>
                <th @click="sortTable('sale_date')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Sale Date</th>
                <th @click="sortTable('industry')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Industry</th>
                <th @click="sortTable('state')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">State</th>
                <th @click="sortTable('aging')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Aging (Days)</th>
                <th @click="sortTable('revenue')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(client, clientIdx) in clients" :key="client.id">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">{{ client.business_name }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ formatDate(client.sale_date) }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ client.industry }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ client.state }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ client.aging }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ client.revenue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Sticky Footer for Key Metrics -->
    <div class="sticky bottom-0 bg-gray-100 p-4 border-t border-gray-300 shadow-md flex justify-between z-50">
      <div># of clients: {{ clientMetrics.totalClients }}</div>
      <div>Aging 0-30: {{ clientMetrics.aging0_30 }}</div>
      <div>31-60: {{ clientMetrics.aging31_60 }}</div>
      <div>61-90: {{ clientMetrics.aging61_90 }}</div>
      <div>90+: {{ clientMetrics.aging90plus }}</div>
      <div># of States: {{ clientMetrics.totalStates }}</div>
      <div># of Industries: {{ clientMetrics.totalIndustries }}</div>
      <div>Total Revenue: {{ clientMetrics.totalRevenue }}</div>
    </div>

    <!-- Pagination Controls -->
    <div class="mt-6 flex justify-between items-center mb-4">
      <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 border rounded bg-gray-200">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 border rounded bg-gray-200">Next</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay.vue';
import { format } from 'date-fns'; // Importing date formatting library

const isLoading = ref(false);
const clients = ref([]); // Store the client data
const industries = ref([]); // Store industry options
const states = ref([
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
  'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA',
  'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
]); // Hardcoded state options
const currentPage = ref(1); // For pagination
const totalPages = ref(1); // Total pages for pagination

// Filters object for the API request
const filters = ref({
  start_date: '',
  end_date: '',
  industry: '',
  state: '',
  aging: '',
});

// Sorting properties
const sortColumn = ref('sale_date'); // Default sort by sale date
const sortDirection = ref('asc'); // Default direction is ascending

// Key metrics for the sticky footer
const clientMetrics = ref({
  totalClients: 0,
  aging0_30: 0,
  aging31_60: 0,
  aging61_90: 0,
  aging90plus: 0,
  totalStates: 0,
  totalIndustries: 0,
  totalRevenue: 0,
});

// Function to format the date
const formatDate = (date) => {
  return date ? format(new Date(date), 'MM/dd/yyyy') : 'N/A';
};

// Function to fetch aging data with filters and sorting
const fetchAgingData = async () => {
  isLoading.value = true;
  const orgId = localStorage.getItem('company'); // Assuming org_id is stored in localStorage

  try {
    const response = await axios.post('/clients/aging', {
      org_id: orgId,
      start_date: filters.value.start_date,
      end_date: filters.value.end_date,
      industry: filters.value.industry,
      state: filters.value.state,
      aging: filters.value.aging,
      page: currentPage.value,
      sort_column: sortColumn.value,
      sort_direction: sortDirection.value,
    });

    clients.value = response.data.data;
    totalPages.value = response.data.last_page;

    // Update the metrics
    clientMetrics.value = {
      totalClients: response.data.metrics.total_clients,
      aging0_30: response.data.metrics.aging0_30,
      aging31_60: response.data.metrics.aging31_60,
      aging61_90: response.data.metrics.aging61_90,
      aging90plus: response.data.metrics.aging90plus,
      totalStates: response.data.metrics.total_states,
      totalIndustries: response.data.metrics.total_industries,
      totalRevenue: response.data.metrics.total_revenue,
    };
  } catch (error) {
    console.error('Error fetching aging data:', error);
  } finally {
    isLoading.value = false;
  }
};

// Function to apply filters and fetch data
const applyFilters = () => {
  currentPage.value = 1; // Reset to the first page when applying new filters
  fetchAgingData();
};

// Function to sort the table
const sortTable = (column) => {
  if (sortColumn.value === column) {
    // Toggle the direction if the same column is clicked
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    // Set the new column and default to ascending order
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
  fetchAgingData(); // Re-fetch the data with the new sorting
};

// Pagination handling
const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    fetchAgingData();
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    fetchAgingData();
  }
};

// Fetch data when component is mounted
onMounted(() => {
  fetchAgingData();
  // Fetch industries for the filters
  fetchIndustries();
});

// Fetch unique industries for the filter dropdown
const fetchIndustries = async () => {
  const orgId = localStorage.getItem('company');

  try {
    const response = await axios.post('/clients/filters', {
      org_id: orgId,
    });
    industries.value = response.data.industries;
  } catch (error) {
    console.error('Error fetching industries:', error);
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
