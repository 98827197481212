<template>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <LoadingOverlay :show="isLoading" />
    <div class="sm:flex sm:items-center justify-between">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold mt-10 leading-6 text-gray-900">Sales Performance</h1>
      </div>
      <!-- Filter Section -->
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex space-x-2">
          <!-- Start Date -->
          <input v-model="filters.start_date" type="date" class="border rounded-md px-3 py-2" placeholder="Start Date" />
          <!-- End Date -->
          <input v-model="filters.end_date" type="date" class="border rounded-md px-3 py-2" placeholder="End Date" />

          <!-- Multi-Select Industry Filter with Scroll -->
          <div class="relative">
            <button @click="toggleIndustryDropdown" class="border rounded-md px-6 py-2">
              {{ filters.industry.length ? filters.industry.join(', ') : 'All Industries' }}
            </button>
            <div v-if="showIndustryDropdown" class="absolute z-50 bg-white border rounded-md shadow-md mt-1 w-48 p-4 max-h-60 overflow-y-auto"> <!-- Proper absolute dropdown -->
              <div v-for="industry in industries" :key="industry" class="flex items-center mb-2">
                <input type="checkbox" :value="industry" v-model="filters.industry" class="mr-2" />
                <label>{{ industry }}</label>
              </div>
            </div>
          </div>

          <!-- Multi-Select Sales Rep Filter with Scroll -->
          <div class="relative">
            <button @click="toggleSalesRepDropdown" class="border rounded-md px-8 py-2">
              {{ filters.sales_rep.length ? filters.sales_rep.join(', ') : 'All Sales Reps' }}
            </button>
            <div v-if="showSalesRepDropdown" class="absolute z-50 bg-white border rounded-md shadow-md mt-1 w-48 p-4 max-h-60 overflow-y-auto"> <!-- Proper absolute dropdown -->
              <div v-for="rep in salesReps" :key="rep" class="flex items-center mb-2">
                <input type="checkbox" :value="rep" v-model="filters.sales_rep" class="mr-2" />
                <label>{{ rep }}</label>
              </div>
            </div>
          </div>

          <!-- Apply Filter Button -->
          <button @click="applyFilters" class="bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 rounded-md">Apply Filters</button>
        </div>
      </div>
    </div>

    <!-- Table Section -->
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle">
          <table class="min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th @click="sortTable('business_name')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8">Customer Name</th>
                <th @click="sortTable('sale_date')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Sale Date</th>
                <th @click="sortTable('sales_rep')" scope="col" class="cursor-pointer sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell">Sales Rep</th>
                <th @click="sortTable('revenue')" scope="col" class="cursor-pointer sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell">Revenue</th>
                <th @click="sortTable('industry')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter">Industry</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(client, clientIdx) in clients.data" :key="client.id">
                <td :class="[clientIdx !== clients.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8']">{{ client.business_name }}</td>
                <td :class="[clientIdx !== clients.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500']">{{ formatDate(client.sale_date) }}</td>
                <td :class="[clientIdx !== clients.length - 1 ? 'border-b border-gray-200' : '', 'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell']">{{ client.sales_rep }}</td>
                <td :class="[clientIdx !== clients.length - 1 ? 'border-b border-gray-200' : '', 'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell']">{{ client.revenue }}</td>
                <td :class="[clientIdx !== clients.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500']">{{ client.industry }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Sticky Footer for Key Metrics -->
    <div class="sticky bottom-0 bg-gray-100 p-4 border-t border-gray-300 shadow-md flex justify-between z-50">
      <div># of clients: {{ clientMetrics.totalClients }}</div>
      <div>Total Revenue: {{ clientMetrics.totalRevenue }}</div>
      <div># of Sales Reps: {{ clientMetrics.uniqueSalesReps }}</div>
      <div># of Industries: {{ clientMetrics.uniqueIndustries }}</div>
    </div>

    <!-- Pagination Controls -->
    <div class="mt-6 flex justify-between items-center mb-4">
      <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 border rounded bg-gray-200">Previous</button>
      <span>Page {{ currentPage }} of {{ clients.last_page }}</span>
      <button @click="nextPage" :disabled="currentPage === clients.last_page" class="px-4 py-2 border rounded bg-gray-200">Next</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay.vue';
import { format } from 'date-fns'; // Importing date formatting library

const isLoading = ref(false);
const clients = ref([]);
const industries = ref([]); // To store the list of unique industries
const salesReps = ref([]); // To store the list of unique sales reps
const currentPage = ref(1);
const pageSize = ref(50); // Limit to 50 records per page
const sortColumn = ref('sale_date'); // Default sort by sale date
const sortDirection = ref('desc'); // Default direction is descending

// Multi-select dropdown state
const showIndustryDropdown = ref(false);
const showSalesRepDropdown = ref(false);

// Filters
const filters = ref({
  start_date: '',
  end_date: '',
  industry: [],
  sales_rep: [],
});

// Key metrics
const clientMetrics = ref({
  totalClients: 0,
  totalRevenue: 0,
  uniqueSalesReps: 0,
  uniqueIndustries: 0,
});

// Function to fetch clients with pagination, filters, and sorting
const fetchClients = async () => {
  isLoading.value = true;
  const orgId = localStorage.getItem('company'); // Assuming company ID is stored in local storage

  try {
    const response = await axios.post('/clients', {
      org_id: orgId,
      start_date: filters.value.start_date,
      end_date: filters.value.end_date,
      industry: filters.value.industry, // Now sending array for industry
      sales_rep: filters.value.sales_rep, // Now sending array for sales rep
      page: currentPage.value,
      limit: pageSize.value,
      sort_column: sortColumn.value,
      sort_direction: sortDirection.value,
    });
    clients.value = response.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
  } finally {
    isLoading.value = false;
  }
};

// Function to fetch metrics separately
const fetchMetrics = async () => {
  const orgId = localStorage.getItem('company');

  try {
    const response = await axios.post('/clients/metrics', {
      org_id: orgId,
      start_date: filters.value.start_date,
      end_date: filters.value.end_date,
      industry: filters.value.industry, // Sending array for industry
      sales_rep: filters.value.sales_rep, // Sending array for sales rep
    });

    clientMetrics.value = {
      totalClients: response.data.total_clients,
      totalRevenue: response.data.total_revenue,
      uniqueSalesReps: response.data.unique_sales_reps,
      uniqueIndustries: response.data.unique_industries,
    };
  } catch (error) {
    console.error('Error fetching metrics:', error);
  }
};

// Toggle the industry dropdown
const toggleIndustryDropdown = () => {
  showIndustryDropdown.value = !showIndustryDropdown.value;
};

// Toggle the sales rep dropdown
const toggleSalesRepDropdown = () => {
  showSalesRepDropdown.value = !showSalesRepDropdown.value;
};

// Function to sort the table
const sortTable = (column) => {
  if (sortColumn.value === column) {
    // Toggle the direction if the same column is clicked
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    // Set the new column and default to ascending order
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
  fetchClients();
};

// Function to fetch all sales reps and industries without pagination
const fetchFilters = async () => {
  const orgId = localStorage.getItem('company');

  try {
    const response = await axios.post('/clients/filters', {
      org_id: orgId,
    });
    // Extract unique industries and sales reps
    industries.value = response.data.industries;
    salesReps.value = response.data.sales_reps;
  } catch (error) {
    console.error('Error fetching filters:', error);
  }
};

// Format date for display
const formatDate = (date) => {
  return date ? format(new Date(date), 'MM/dd/yyyy') : 'N/A'; // Example format: MM/DD/YYYY
};

// Pagination
const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    fetchClients();
  }
};

const nextPage = () => {
  if (currentPage.value < clients.value.last_page) {
    currentPage.value++;
    fetchClients();
  }
};

// Apply filters and fetch clients + metrics
const applyFilters = () => {
  currentPage.value = 1; // Reset to first page when filters are applied
  fetchClients();
  fetchMetrics(); // Fetch the metrics separately
};

onMounted(() => {
  fetchClients();
  fetchFilters(); // Fetch unique industries and sales reps on mount
  fetchMetrics(); // Fetch metrics on mount
});
</script>

<style scoped>
/* Add a maximum height to the dropdowns and make them scrollable */
.max-h-60 {
  max-height: 15rem; /* Equivalent to about 15 items */
}

.overflow-y-auto {
  overflow-y: auto;
}

/* Ensure dropdowns appear above sticky headers */
.dropdown {
  z-index: 50; /* High z-index to overlay sticky header */
  position: absolute; /* Ensures dropdown is positioned above */
  background-color: white; /* Adds background to dropdown */
}
</style>
