<template>
  <div class="relative px-4 sm:px-6 lg:px-8">
    <LoadingOverlay :show="isLoading" />
    <div class="sm:flex sm:items-center justify-between">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold mt-10 leading-6 text-gray-900">Customer Segmentation / Product Performance</h1>
      </div>
      <!-- Filter Section -->
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex space-x-2">
          <!-- Start Date -->
          <input v-model="filters.start_date" type="date" class="border rounded-md px-3 py-2" placeholder="Start Date" />
          <!-- End Date -->
          <input v-model="filters.end_date" type="date" class="border rounded-md px-3 py-2" placeholder="End Date" />

          <!-- State Filter -->
          <select v-model="filters.state" class="border rounded-md px-6 py-2">
            <option value="">All States</option>
            <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
          </select>

          <!-- Price Point Filter -->
          <select v-model="filters.price_point" class="border rounded-md px-6 py-2">
            <option value="">All Price Points</option>
            <option v-for="point in pricePoints" :key="point" :value="point">{{ point | currency }}</option>
          </select>

          <!-- Apply Filter Button -->
          <button @click="applyFilters" class="bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 rounded-md">Apply Filters</button>
        </div>
      </div>
    </div>

    <!-- Table Section -->
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle">
          <table class="min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th @click="sortTable('industry')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Industry</th>
                <th @click="sortTable('num_clients')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"># of Clients</th>
                <th @click="sortTable('num_states')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"># of States</th>
                <th @click="sortTable('avg_price')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Avg Price</th>
                <th @click="sortTable('total_revenue')" scope="col" class="cursor-pointer sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(client, clientIdx) in clients" :key="client.industry">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">{{ client.industry }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ client.num_clients }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ client.num_states }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ client.avg_price | currency }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ client.total_revenue | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Pagination Controls -->
    <div class="mt-6 flex justify-between items-center mb-4">
      <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 border rounded bg-gray-200">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 border rounded bg-gray-200">Next</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import LoadingOverlay from './LoadingOverlay.vue';

const isLoading = ref(false);
const clients = ref([]); // Store the client data
const states = ref([
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
  'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA',
  'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
]); // Hardcoded state options
const currentPage = ref(1); // For pagination
const totalPages = ref(1); // Total pages for pagination
const pricePoints = ref([]); // For price point filter

// Filters object for the API request
const filters = ref({
  start_date: '',
  end_date: '',
  state: '',
  price_point: '',
});

// Sorting properties
const sortColumn = ref('total_revenue'); // Default sort by revenue
const sortDirection = ref('desc'); // Default direction is descending

// Function to fetch data with filters
const fetchClientData = async () => {
  isLoading.value = true;
  const orgId = localStorage.getItem('company'); // Assuming org_id is stored in localStorage

  try {
    const response = await axios.post('/clients/segmentation', {
      org_id: orgId,
      start_date: filters.value.start_date,
      end_date: filters.value.end_date,
      state: filters.value.state,
      price_point: filters.value.price_point,
      page: currentPage.value,
      sort_column: sortColumn.value,
      sort_direction: sortDirection.value,
    });

    clients.value = response.data.data;
    totalPages.value = response.data.last_page;
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    isLoading.value = false;
  }
};

// Function to fetch unique price points
const fetchPricePoints = async () => {
  const orgId = localStorage.getItem('company');

  try {
    const response = await axios.post('/clients/price-points', { org_id: orgId });

    // Convert object to array and sort
    const pricePointsArray = Object.values(response.data.price_points).map(Number); // Convert to array and ensure numeric values
    pricePoints.value = pricePointsArray.sort((a, b) => b - a); // Sort in descending order
  } catch (error) {
    console.error('Error fetching price points:', error);
  }
};

// Function to apply filters and fetch data
const applyFilters = () => {
  currentPage.value = 1; // Reset to the first page when applying new filters
  fetchClientData();
};

// Sorting functionality
const sortTable = (column) => {
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
  fetchClientData(); // Re-fetch the data with the new sorting
};

// Pagination handling
const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    fetchClientData();
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    fetchClientData();
  }
};

// Fetch data when component is mounted
onMounted(() => {
  fetchClientData();
  fetchPricePoints();
});
</script>

<style scoped>
/* Add any necessary styles here */
</style>
