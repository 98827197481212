<template>
  <div class="container mx-auto">
    <form class="text-center my-4" @submit.prevent="fetchReportData">
      <!-- Date Range Inputs -->
      <label>Date range: </label>
      <input v-model="filters.start_date" type="date" required />
      <span> - </span>
      <input v-model="filters.end_date" type="date" required />

      <!-- Dataset Selection -->
      <label>Data: </label>
      <select v-model="filters.dataset" required>
        <option value="arb">ARB</option>
        <option value="active_clients">Active Clients</option>
        <option value="arb_and_active_clients">ARB and Active Clients</option>
      </select>

      <!-- Submit Button -->
      <button type="submit" class="ml-4 px-4 py-2 bg-blue-500 text-white rounded">Submit</button>
    </form>

    <!-- Chart Container -->
    <canvas id="arb-chart" class="mt-8"></canvas>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { Chart, LineController, LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns'; // Import the date-fns adapter for time scales

// Register the necessary components of Chart.js
Chart.register(LineController, LineElement, PointElement, LinearScale, TimeScale, Title, Tooltip, Legend);

export default {
  setup() {
    const filters = ref({
      start_date: '2023-01-01',
      end_date: '2024-10-20',
      dataset: 'arb_and_active_clients', // Default option
    });

    const chartInstance = ref(null); // Reference to the chart instance

    const fetchReportData = async () => {
      try {
        const response = await axios.post('/arb-report', {
          org_id: localStorage.getItem('company'), // Assume org_id is stored in localStorage
          start_date: filters.value.start_date,
          end_date: filters.value.end_date,
          dataset: filters.value.dataset,
        });

        // Process and display the data in Chart.js
        renderChart(response.data);
      } catch (error) {
        console.error('Error fetching ARB report:', error);
      }
    };

    // Function to render the chart using Chart.js
    const renderChart = (data) => {
      // Extract the date labels, ARB, and clients data from the response
      const labels = data.map(item => item.report_date);
      const arbData = data.map(item => item.arb);
      const clientsData = data.map(item => item.clients);

      // If a chart instance already exists, destroy it before creating a new one
      if (chartInstance.value) {
        chartInstance.value.destroy();
      }

      // Create a new Chart.js instance
      const ctx = document.getElementById('arb-chart').getContext('2d');
      chartInstance.value = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'ARB',
              data: arbData,
              borderColor: '#3e95cd',
              fill: false,
              yAxisID: 'y-axis-arb',
            },
            {
              label: 'Clients',
              data: clientsData,
              borderColor: '#8e5ea2',
              fill: false,
              yAxisID: 'y-axis-clients',
            },
          ],
        },
        options: {
          responsive: true,
          title: {
            display: true,
            text: 'ARB and Total Active Clients',
          },
          tooltips: {
            intersect: false,
            mode: 'nearest',
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day', // Define the time unit based on your data granularity
                tooltipFormat: 'PP', // Date format for tooltips (from date-fns)
              },
            },
            'y-axis-arb': {
              position: 'left',
              ticks: {
                beginAtZero: true,
              },
            },
            'y-axis-clients': {
              position: 'right',
              ticks: {
                beginAtZero: true,
              },
            },
          },
        },
      });
    };

    onMounted(() => {
      fetchReportData(); // Fetch data and display chart on component mount
    });

    return {
      filters,
      fetchReportData,
    };
  },
};
</script>

<style scoped>
.container {
  width: 80%;
  margin: auto;
}
</style>
